














import { Component, Vue } from 'vue-property-decorator';
import WarningPopup from '@/modules/common/components/popup-warning.vue';

@Component({
    components: {
        WarningPopup,
    },
    filters: {
        DistanceFilter: (value: number) => {
            const formattedValue = Number(value)
                .toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 });

            return formattedValue;
        },
    },
})
export default class WarningDistancePopup extends Vue {
    get distance() {
        return this.$route.params.distance;
    }

    close() {
        this.$router.back();
    }
}
